// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".toTop_toTop__GZy9t {\n    position: fixed;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    right: 100px;\n    height: 40px;\n    width: 40px;\n    max-height: 40px !important;\n    max-width: 40px !important;\n    cursor: pointer;\n    color: black;\n    background: rgba(10,245,202, .25);\n    border-radius: 50%;\n    transition: .5s ease-out;\n    z-index: 100;\n}\n.toTop_toTop__GZy9t:hover {\n    background: rgba(10,245,202, .35);\n}\n\n.toTop_toTop__GZy9t img {\n    padding: 5px;\n    max-height: 40px !important;\n    max-width: 40px !important;\n}\n\n.toTop_hide__Mxkxa {\n    bottom: -40px;\n}\n.toTop_show__1HwGX {\n    bottom: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/toTop/toTop.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,2BAA2B;IAC3B,0BAA0B;IAC1B,eAAe;IACf,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,wBAAwB;IACxB,YAAY;AAChB;AACA;IACI,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;AACA;IACI,YAAY;AAChB","sourcesContent":[".toTop {\n    position: fixed;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    right: 100px;\n    height: 40px;\n    width: 40px;\n    max-height: 40px !important;\n    max-width: 40px !important;\n    cursor: pointer;\n    color: black;\n    background: rgba(10,245,202, .25);\n    border-radius: 50%;\n    transition: .5s ease-out;\n    z-index: 100;\n}\n.toTop:hover {\n    background: rgba(10,245,202, .35);\n}\n\n.toTop img {\n    padding: 5px;\n    max-height: 40px !important;\n    max-width: 40px !important;\n}\n\n.hide {\n    bottom: -40px;\n}\n.show {\n    bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toTop": "toTop_toTop__GZy9t",
	"hide": "toTop_hide__Mxkxa",
	"show": "toTop_show__1HwGX"
};
export default ___CSS_LOADER_EXPORT___;
